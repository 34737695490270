<router>
{
    name: 'Sales Tool Export',
}
</router>
<template lang="pug">
v-container
    v-card(light, flat)
        v-row
            v-col
                .text-h5 Export SAT Project
        v-row
            ol
                li Select a bid from the dropdown below.
                li Click "Export".
                li When the server finishes generating the data, it will be copied to your clipboard.
                li Log into the desired InfoScan system.
                li Open the Navigator application.
                li Right-click the node the data should be imported to.
                li Select "Import SAT Project".
                li Paste in the data.
                li Click "Import".

        v-row
            v-col(cols='6').d-flex
                v-combobox(outlined, dense, label='Bid', :items='activeBids', item-text='name', item-value='id', color='brand', v-model='selectedBid')
                v-btn.ml-2.white--text(@click='generateExport', :loading='generatingData', color='brand') Export

    v-dialog(v-model='infoDialog', max-width='400px')
        v-card(light)
            v-card-title Info
            v-card-text
                p {{infoMessage}}
            v-card-actions
                v-spacer
                v-btn(text, @click='infoDialog = false') Close

</template>
<script>

export default {
    meta: {
        role: 'sat'
    },
    components: {
    },
    data () {
        return {
            accessKey: 'infoscanexport',
            activeBids: [],
            selectedBid: null,
            generatingData: false,
            infoDialog: false,
            infoMessage: null
        };
    },
    methods: {
        /**
         * Gets recent MMLs to have something to populate the list with.
         */
        getData () {
            this.getActiveBids();
        },
        /**
         * Requests bids from the server that have been marked as won (but not closed out)
         */
        async getActiveBids () {
            const bids = await this.sendCommand({
                action: 'getActiveBids',
                parameters: {}
            });
            this.activeBids = bids;
        },
        async generateExport () {
            this.generatingData = true;
            const result = await this.sendCommand({
                action: 'exportSatProject',
                parameters: this.selectedBid
            });
            this.generatingData = false;
            if (result.err) {
                this.infoMessage = result.err;
                return;
            }
            const outputData = JSON.stringify(result.points);
            navigator.clipboard.writeText(outputData);
            this.infoMessage = 'Data copied to clipboard!';
        }
    },
    computed: {

    },
    watch: {
        infoMessage (message) {
            if (message) {
                this.infoDialog = true;
            }
        },
        infoDialog (visible) {

        }
    },
    mounted () {
        this.getData();
    }
};
</script>

<style scoped>
</style>
